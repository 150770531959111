import { authService } from "./authService";
import client from "./client";

const BASE_URL = "/wp-json/wp/v2/";

const fetchIceContacts = async () => {
  return client.get(`${BASE_URL}posts/57?acf_format=standard`);
};
const fetchEmergencyPlans = async () => {
  return client.get(`${BASE_URL}posts/54?acf_format=standard`);
};

const iceInfo = {
  fetchIceContacts,
  fetchEmergencyPlans,
};

export default iceInfo;
