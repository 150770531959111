import axios from "axios";
import { authService } from "./authService";
import { useNavigate } from "react-router";

const URL = "https://ice.wiznet.se";

const client = axios.create({
  baseURL: URL,
});

client.interceptors.request.use((config) => {
  config.headers.Authorization = `Bearer ${authService.tokenValue}`;
  return config;
});

client.interceptors.response.use(
  (response) => {
    return response;
  },
  (err) => {
    if (err.response && [400, 401, 403].includes(err.response.status)) {
      authService.clearCache();
    }
  }
);

export default client;
