import React from "react";
import { useRecoilValue } from "recoil";
import { emergencyPlanState, currentUserState } from "../state/list/ice";
import useFetchEmergencyPlans from "../hooks/useFetchEmergencyPlans";
import { Link } from "react-router-dom";
import Arrow from "../assets/icons/arrowfront.svg";

const EmergencyPlanTitles: React.FC<{ searchTerm: string }> = ({
  searchTerm,
}) => {
  const data = useRecoilValue(emergencyPlanState);

  const { isLoading, isError } = useFetchEmergencyPlans();

  if (isLoading) {
    return (
      <div className="flex items-center justify-center h-screen">
        <div className="text-white text-xl">Laddar...</div>
      </div>
    );
  }

  if (isError || !data) {
    return (
      <div className="flex items-center justify-center h-screen">
        <div className="text-white text-xl">
          Kunde inte hämta checklistor. Försök igen senare.
        </div>
      </div>
    );
  }

  const filteredPlans = data.acf.emergency_plans
    .filter((plan) =>
      plan.title.toLowerCase().includes(searchTerm.toLowerCase())
    )
    .sort((a, b) => a.title.localeCompare(b.title));

  return (
    <div className="xl:pt-36 xl:flex xl:items-center xl:justify-center">
      <div className="pt-40 xl:pt-4 max-w-8xl px-8 xl:pb-6 xl:w-[70%] xl:bg-box-color xl:rounded-lg xl:max-h-[70vh] 2k:max-h-[90vh] xl:overflow-y-auto no-scrollbar">
        <div className="bg-ebox-color font-albert-sans text-normal-white px-10 pt-4 xl:mt-4 mb-4 pb-4 rounded-md max-w-xs">
          <p className="pb-1 font-bold text-lg">Nödsituation?</p>
          <ol>
            <li>1. Ring 112</li>
            <li>2. Skrik på hjälp</li>
            <li>3. Välj rätt checklista</li>
          </ol>
        </div>

        {filteredPlans.map((plan, index) => (
          <div key={plan.id} className="pt-5">
            <Link to={`/plan/${plan.id}`}>
              <div className="flex justify-between font-albert-sans text-normal-white">
                <p className="hover:underline">{plan.title}</p>
                <img src={Arrow} alt="Arrow" />
              </div>
            </Link>
            {index !== filteredPlans.length - 1 && (
              <div className="border-b-custom pt-5 w-[100%] mx-auto"></div>
            )}
          </div>
        ))}
        <div className="border-b-custom xl:hidden pt-5 w-[100%] mx-auto"></div>
      </div>
    </div>
  );
};

export default EmergencyPlanTitles;
