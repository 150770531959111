import React, { useState } from "react";
import EmployeeNames from "../components/EmployeeNames";
import SearchField from "../components/SearchField";

const ContactPage: React.FC = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [sortMode, setSortMode] = useState("name");

  return (
    <div>
      <SearchField setSearchTerm={setSearchTerm} setSortMode={setSortMode} />
      <EmployeeNames searchTerm={searchTerm} sortMode={sortMode} />
    </div>
  );
};

export default ContactPage;
