import React from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useRecoilValue } from "recoil";

import { iceContactState } from "../state/list/ice";

import useFetchIceContacts from "../hooks/useFetchIceContacts";

import ArrowBackIcon from "../assets/icons/arrow_back_ios_new.svg";

const IceContacts: React.FC = () => {
  const { employee_number } = useParams<{ employee_number?: string }>();
  const data = useRecoilValue(iceContactState);
  const { isLoading, isError } = useFetchIceContacts();
  const navigate = useNavigate();

  const noNotes = "Den här personen har inte lagt till några anteckningar.";
  const noAllergies = "Den här personen har inte angett till några allergier.";

  if (isLoading) {
    return (
      <div className="flex items-center justify-center h-screen">
        <div className="text-white text-xl">Laddar...</div>
      </div>
    );
  }

  if (isError) {
    return (
      <div className="flex items-center justify-center h-screen">
        <div className="text-white text-xl">
          Kunde inte hämta kontakter. Försök igen senare.
        </div>
      </div>
    );
  }

  const contact = data?.acf.ice_contacts.find(
    (c) => c.employee_number === employee_number
  );

  if (!contact) {
    return (
      <div className="flex items-center justify-center h-screen">
        <div className="text-white text-xl">Kunde inte hitta kontakt.</div>
      </div>
    );
  }

  const handleGoBack = () => {
    navigate("/contact");
  };

  return (
    <div className="pb-24 lg:pb-0 lg:pt-16 2k:pt-32 lg:h-screen items-center flex justify-center lg:bg-darkblue-ice bg-box-color-ice">
      <div className="h-full flex flex-col justify-center items-center relative">
        <div className="lg:relative 2k:px-72 2k:pt-32 2k:pb-48 lg:px-32 lg:pt-4 lg:pb-10 bg-box-color flex-col items-center lg:border-gray-700 lg:rounded-xl ">
          <div className="pt-6">
            <div className="flex flex-col lg:flex-row justify-center items-center mt-10 lg:mt-0">
              <div className="absolute lg:-top-24 lg:left-0 hidden lg:block text-normal-white font-albert-sans">
                Ensolution | SAFETY
              </div>
              <div className="lg:w-1/4">
                <div
                  onClick={handleGoBack}
                  className="absolute top-10 lg:-top-14 left-0 lg:left-0 cursor-pointer flex items-center space-x-3"
                >
                  <div className="bg-darkblue lg:bg-box-color p-2 rounded-full">
                    <img src={ArrowBackIcon} alt="Vector" />
                  </div>
                  <div className="text-normal-white font-albert-sans hidden lg:block hover:underline">
                    Tillbaka
                  </div>
                </div>
                <div className="mb-4 lg:mb-0">
                  <img
                    src={contact.employee_image}
                    className="w-[121px] h-[121px] rounded-full"
                  />
                </div>
              </div>
              <div className="lg:w-3/4 text-center lg:text-start">
                <p className="font-albert-sans text-lg text-normal-white lg:-ml-6 mb-3 lg:mb-0">
                  {contact.employee_firstname} {contact.employee_lastname}
                </p>
                <p className="hidden lg:block text-normal-white font-albert-sans text-oppacity-color text-sm lg:-ml-6">
                  {contact.employee_email}
                </p>
                <p className="text-login hover:underline lg:-ml-6 font-albert-sans -mt-3 mb-3 lg:mb-0 lg:mt-0">
                  <a href={`tel:${contact.employee_phonenumber}`}>
                    {contact.employee_phonenumber}
                  </a>
                </p>
                <div
                  className={`rounded-2xl w-[86px] h-6 mx-auto lg:-ml-6 overflow-hidden flex items-center lg:mt-3 justify-center 
                  ${contact.employee_office === "Halmstad"
                      ? "bg-location-hstad text-sky-700"
                      : contact.employee_office === "Stockholm"
                        ? "bg-location-sthlm text-cyan-700"
                        : contact.employee_office === "Göteborg"
                          ? "bg-location-gbg text-indigo-500"
                          : "bg-indigo-400 text-indigo-600"
                    }`}
                >
                  <p className="font-albert-sans text-sm font-bold">
                    {contact.employee_office}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="mt-4 lg:grid lg:grid-cols-2 lg:gap-6">
            <div className="flex flex-col">
              <p className="font-albert-sans text-sm mb-3 lg:mb-2 text-normal-white">
                Nödkontakter
              </p>
              <div className="bg-darkblue rounded-xl h-72 overflow-y-auto scrollbar: no-scrollbar">
                <div className="font-albert-sans text-normal-white">
                  {contact.contact_group.map((groupContact, idx) => (
                    <div key={idx}>
                      <p className="text-xs px-4 pt-3 pb-2">
                        {groupContact.contact_relation}
                      </p>
                      <p className="font-albert-sans text-normal-white px-4 pb-0.5">
                        {groupContact.contact_firstname}{" "}
                        {groupContact.contact_lastname}
                      </p>
                      <p className="text-login px-4 hover:underline">
                        <a href={`tel:${groupContact.contact_phonenumber}`}>
                          {groupContact.contact_phonenumber}
                        </a>
                      </p>
                      {idx !== contact.contact_group.length &&
                        (contact.contact_group.length !== 3 || idx < 2) && (
                          <div className="border-b-custom pb-2"></div>
                        )}
                    </div>
                  ))}
                </div>
              </div>
            </div>
            <div>
              <div className="font-albert-sans mb-8 lg:mb-0">
                <p className="lg:mt-0 mt-4 text-sm mb-3 lg:mb-2 text-normal-white">
                  Allergier
                </p>
                <div className="lg:h-32 w-80 text-oppacity-color text-xs bg-darkblue p-4 rounded-xl">
                  {contact.employee_allergies !== "" ? (
                    <p>{contact.employee_allergies}</p>
                  ) : (
                    <p className="opacity-30 italic">{noAllergies}</p>
                  )}
                </div>
                <p className="lg:mt-0 mt-4 mb-3 lg:mb-1.5 pt-1.5 text-sm text-normal-white">
                  Anteckningar
                </p>
                <div className="lg:h-32 w-80 text-oppacity-color text-xs bg-darkblue p-4 rounded-xl">
                  {contact.employee_notes !== "" ? (
                    <p>{contact.employee_notes}</p>
                  ) : (
                    <p className="opacity-30 italic">{noNotes}</p>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default IceContacts;
