import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

const Footer = () => {
  const navigate = useNavigate();
  const [selectedButton, setSelectedButton] = useState("contacts");
  const commonClasses =
    "font-albert-sans rounded-[50px] px-7 py-2 overflow-hidden text-normal-white items-center flex";

  return (
    <div className="cursor-pointer w-[324px] h-[59px] rounded-[50px] flex justify-between p-1.5 second-button-color fixed md:bottom-8 bottom-10 left-1/2 transform -translate-x-1/2 z-50">
      <div
        className={`${commonClasses} ${
          selectedButton === "contacts" ? "bg-darkblue" : "second-button-color"
        }`}
        onClick={() => {
          navigate("/contact");
          setSelectedButton("contacts");
        }}
      >
        Medarbetare
      </div>
      <div
        className={`${commonClasses} ${
          selectedButton === "plans" ? "bg-darkblue" : "second-button-color"
        }`}
        onClick={() => {
          navigate("/plan");
          setSelectedButton("plans");
        }}
      >
        Checklistor
      </div>
    </div>
  );
};

export default Footer;
