import { useSetRecoilState } from "recoil";
import { useQuery } from "react-query";
import iceInfo from "../api/ice";
import { iceContactState } from "../state/list/ice";

const useFetchIceContacts = () => {
  const setIceContacts = useSetRecoilState(iceContactState);
  return useQuery(["useFetchIceContacts"], async () => {
    const res = await iceInfo.fetchIceContacts();
    setIceContacts(res?.data);
    return res;
  });
};

export default useFetchIceContacts;
