import "./theme/index.css";
import React, { useEffect } from "react";
import IceInfoPage from "./pages/IceInfoPage";
import Footer from "./components/Footer";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import EmergencyInfoPage from "./pages/EmergencyInfoPage";
import ContactPage from "./pages/ContactPage";
import EmergencyPage from "./pages/EmergencyPage";
import Login from "./components/Login";
import { useLocation } from "react-router-dom";

function Content() {
  const location = useLocation();
  const showFooter = location.pathname !== "/";

  return (
    <div>
      {showFooter && <Footer />}
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/contact" element={<ContactPage />} />
        <Route path="/contact/:employee_number" element={<IceInfoPage />} />
        <Route path="/plan" element={<EmergencyPage />} />
        <Route path="/plan/:id" element={<EmergencyInfoPage />} />
      </Routes>
    </div>
  );
}

const App: React.FC = () => {
  useEffect(() => {
    // Check if service workers are supported
    if ("serviceWorker" in navigator) {
      // Use the window load event to keep the page load performant
      window.addEventListener("load", () => {
        navigator.serviceWorker
          .register("/service-worker.js")
          .then((registration) => {
            console.log(
              "Service Worker registered with scope: ",
              registration.scope
            );
          })
          .catch((error) => {
            console.log("Service Worker registration failed: ", error);
          });
      });
    }
  }, []); // Empty dependency array means this useEffect runs once when component mounts

  return (
    <div>
      <Router>
        <Content />
      </Router>
    </div>
  );
};

export default App;
