import { useSetRecoilState } from "recoil";
import { useQuery } from "react-query";
import iceInfo from "../api/ice";
import { emergencyPlanState } from "../state/list/ice";

const useFetchEmergencyPlans = () => {
  const setEmergencyPlans = useSetRecoilState(emergencyPlanState);
  return useQuery(["fetchEmergencyPlans"], async () => {
    const res = await iceInfo.fetchEmergencyPlans();
    setEmergencyPlans(res?.data);
    return res;
  });
};

export default useFetchEmergencyPlans;
