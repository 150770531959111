import React, { useState, useRef, useEffect } from "react";
import Search from "../assets/icons/search.svg";
import Sort from "../assets/icons/sorttwo.svg";
import { useLocation } from "react-router-dom";

interface SearchBarProps {
  setSearchTerm: (value: string) => void;
  setSortMode: React.Dispatch<React.SetStateAction<string>>;
}

const SearchField: React.FC<SearchBarProps> = ({
  setSearchTerm,
  setSortMode,
}) => {
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);
  const dropdownRef = useRef<HTMLDivElement | null>(null);
  const location = useLocation();

  const chooseSortMode = (mode: string) => {
    setSortMode(mode);
    setIsDropdownVisible(false);
  };

  let displayText;
  let showButton;
  let marginChange;
  let searchWidth;
  if (location.pathname === "/contact") {
    displayText = "Medarbetare";
    showButton = "block";
    marginChange = "mr-2";
    searchWidth = "w-60";
  } else if (location.pathname === "/plan") {
    displayText = "Checklistor";
    showButton = "hidden";
    marginChange = "mr-0";
    searchWidth = "w-76";
  }

  return (
    <div className="xl:flex xl:justify-center xl:items-start xl:w-full">
      <div className="w-full max-w-8xl xl:w-[70%] bg-darkblue pt-12 xl:pt-12 pb-4 fixed z-10">
        <div className="hidden xl:block text-normal-white font-albert-sans">
          Ensolution | SAFETY
        </div>
        <div className="font-albert-sans flex justify-center xl:justify-between xl:items-center text-normal-white">
          <p className="hidden xl:block text-3xl">{displayText}</p>
          <div className="flex">
            <img
              src={Search}
              alt="Search"
              className="bg-box-color rounded-l-md pl-3"
            />
            <input
              type="text"
              placeholder="Sök"
              onChange={(e) => setSearchTerm(e.target.value)}
              className={`text-normal-white bg-box-color py-3 px-2 rounded-r-md ${marginChange} ${searchWidth} focus:outline-none`}
            />
            <div className="relative">
              <button
                className={`bg-box-color text-normal-white px-4 py-3 rounded-md hover:outline ${showButton}`}
                onClick={() => setIsDropdownVisible(!isDropdownVisible)}
              >
                <img src={Sort} alt="Sort" />
              </button>
              {isDropdownVisible && (
                <div
                  ref={dropdownRef}
                  className="absolute top-full right-0 mt-2 bg-box-color border border-gray-700 text-normal-white font-albert-sans rounded-md"
                >
                  <p
                    onClick={() => chooseSortMode("name")}
                    className="text-left pr-16 pl-4 py-3 hover:underline cursor-pointer border-b-custom"
                  >
                    Namn
                  </p>
                  <p
                    onClick={() => chooseSortMode("office")}
                    className="text-left pr-16 pl-4 py-3 hover:underline cursor-pointer"
                  >
                    Kontor
                  </p>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SearchField;
