import React, { useState } from "react";
import { useRecoilValue, useResetRecoilState } from "recoil";
import { iceContactState } from "../state/list/ice";
import useFetchIceContacts from "../hooks/useFetchIceContacts";
import { Link, useNavigate } from "react-router-dom";
import { authService } from "../api/authService";

import Logout from "../assets/icons/logout.svg";

const EmployeeNames: React.FC<{ searchTerm: string; sortMode: string }> = ({
  searchTerm,
  sortMode,
}) => {
  const data = useRecoilValue(iceContactState);
  const { isLoading, isError } = useFetchIceContacts();
  const navigate = useNavigate();
  const resetState = useResetRecoilState(iceContactState);
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);

  const logout = () => {
    authService.clearCache();
    resetState;
    console.log(authService.tokenValue);
    navigate("/");
  };

  if (isLoading) {
    return (
      <div className="flex items-center justify-center h-screen">
        <div className="text-white text-xl">Laddar...</div>
      </div>
    );
  }

  if (isError || !data) {
    return (
      <div className="flex items-center justify-center h-screen">
        <div className="text-white text-xl">
          Kunde inte hämta kontakter. Försök igen senare.
        </div>
      </div>
    );
  }

  const extendedContacts = data?.acf.ice_contacts.map((employee) => ({
    ...employee,
    ice_contact_count: employee.contact_group.length,
  }));

  const filteredContacts = extendedContacts
    .filter(
      (contact) =>
        contact.employee_firstname
          .toLowerCase()
          .includes(searchTerm.toLowerCase()) ||
        contact.employee_office.toLowerCase().includes(searchTerm.toLowerCase())
    )
    .sort((a, b) => {
      if (sortMode === "office") {
        const officeComparison = a.employee_office.localeCompare(
          b.employee_office
        );

        if (officeComparison === 0) {
          return a.employee_firstname.localeCompare(b.employee_firstname);
        }

        return officeComparison;
      }

      return a.employee_firstname.localeCompare(b.employee_firstname);
    });

  return (
    <div>
      <div className="xl:pt-36 xl:flex xl:items-center xl:justify-center">
        <div className="pt-28 xl:pt-2 pb-4 mb-52 xl:mb-0 max-w-8xl xl:w-[70%] xl:bg-box-color xl:rounded-lg xl:max-h-[70vh] 2k:max-h-[80vh] xl:overflow-y-auto no-scrollbar">
          <div className="font-albert-sans flex justify-between text-normal-white px-6 text-sm pt-6 pb-2">
            <p>NAMN</p>
            <p className="hidden xl:block">ICE-KONTAKTER</p>
            <p>KONTOR</p>
          </div>
          <div className="border-t-custom w-[90%] md:w-[94%] xl:w-[97%] 2k:w-[98%] mx-auto"></div>
          {filteredContacts.map((contact, index) => (
            <div key={contact.employee_number} className="pt-4">
              <Link to={`/contact/${contact.employee_number}`}>
                <div className="flex items-center px-6 relative">
                  <img
                    src={contact.employee_image}
                    alt={`${contact.employee_firstname} ${contact.employee_lastname}`}
                    className="w-[35px] h-[35px] rounded-full"
                  />
                  <div className="text-left ml-4 flex-grow">
                    <p className="font-albert-sans text-base py-1 hover:underline text-normal-white">
                      {contact.employee_firstname} {contact.employee_lastname}
                    </p>
                  </div>
                  <div className="hidden xl:block w-[30px] h-[30px] rounded-full button-color absolute left-1/2 transform -translate-x-1/2 -translate-y-1/2 top-1/2">
                    <span className="font-albert-sans text-normal-white text-sm absolute inset-0 flex items-center justify-center">
                      {contact.ice_contact_count}
                    </span>
                  </div>
                  <div
                    className={`rounded-2xl mt-1 w-[86px] h-6 overflow-hidden flex items-center justify-center ${
                      contact.employee_office === "Halmstad"
                        ? "bg-location-hstad text-sky-700"
                        : contact.employee_office === "Stockholm"
                        ? "bg-location-sthlm text-cyan-700"
                        : contact.employee_office === "Göteborg"
                        ? "bg-location-gbg text-indigo-500"
                        : "bg-indigo-400 text-indigo-600"
                    }`}
                  >
                    <p className="font-albert-sans text-sm font-bold">
                      {contact.employee_office}
                    </p>
                  </div>
                </div>
              </Link>
              {index !== filteredContacts.length - 1 && (
                <div className="border-b-custom pt-4 w-[90%] md:w-[94%] xl:w-[97%] 2k:w-[98%] mx-auto"></div>
              )}
            </div>
          ))}

          <div className="border-b-custom xl:hidden pt-4 w-[90%] md:w-[94%] xl:w-[97%] 2k:w-[98%] mx-auto"></div>
          {!isDropdownVisible && (
            <div className="xl:hidden flex justify-end mt-4 mr-4">
              <div className="rounded-2xl w-[84px] cursor-pointer h-[32px] flex items-center justify-center border pb-0.5">
                <div className="text-white flex text-[10px]">
                  <div
                    className="pr-2 font-albert-sans"
                    onClick={() => setIsDropdownVisible(!isDropdownVisible)}
                  >
                    Logga ut
                  </div>
                  <img src={Logout} />
                </div>
              </div>
            </div>
          )}
          {isDropdownVisible && (
            <div className="pt-3 xl:hidden font-albert-sans">
              <div className="absolute right-6 flex bg-box-color border-custom rounded-xl p-6">
                <div className="text-white">
                  Är du säker på att du vill logga ut?
                </div>
                <button
                  className="cursor-pointer hover:underline text-white pl-5"
                  onClick={() => setIsDropdownVisible(!isDropdownVisible)}
                >
                  Avbryt
                </button>
                <button
                  className="cursor-pointer hover:underline text-login pl-5"
                  onClick={logout}
                >
                  Logga ut
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
      {!isDropdownVisible && (
        <div className="text-white fixed bottom-5 left-5 hover:underline cursor-pointer hidden xl:block font-albert-sans">
          <div className="flex">
            <div
              className="pr-2"
              onClick={() => setIsDropdownVisible(!isDropdownVisible)}
            >
              Logga ut
            </div>
            <img className="pt-1" src={Logout} />
          </div>
        </div>
      )}
      {isDropdownVisible && (
        <div className="fixed bottom-5 left-5 hidden xl:block font-albert-sans">
          <div className="flex bg-box-color border-custom rounded-xl p-6">
            <div className="text-white">
              Är du säker på att du vill logga ut?
            </div>
            <button
              className="cursor-pointer hover:underline text-white pl-5"
              onClick={() => setIsDropdownVisible(!isDropdownVisible)}
            >
              Avbryt
            </button>
            <button
              className="cursor-pointer hover:underline text-login pl-5"
              onClick={logout}
            >
              Logga ut
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default EmployeeNames;
