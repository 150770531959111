import { atom } from "recoil";
import {
  IceContactType,
  EmergencyPlanType,
  CurrentUserType,
} from "../../types/ice";

export const emergencyPlanState = atom<EmergencyPlanType>({
  key: "emergencyPlanState",
  default: undefined,
});

export const currentUserState = atom<CurrentUserType>({
  key: "currentUserState",
  default: undefined,
});

export const iceContactState = atom<IceContactType>({
  key: "iceContactState",
  default: undefined,
});
