import React from "react";
import IceContacts from "../components/IceContacts";

const IceInfoPage: React.FC = () => {
  return (
    <div>
      <IceContacts />
    </div>
  );
};

export default IceInfoPage;
