import React from "react";
import EmergencyPlans from "../components/EmergencyPlans";

const EmergencyInfoPage: React.FC = () => {
  return (
    <div>
      <EmergencyPlans />
    </div>
  );
};

export default EmergencyInfoPage;
