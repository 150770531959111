import React, { useState } from "react";
import EmergencyPlanTitles from "../components/EmergencyPlanTitles";
import SearchField from "../components/SearchField";

const EmergencyPage: React.FC = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [sortMode, setSortMode] = useState("name");

  return (
    <div>
      <div>
        <SearchField setSearchTerm={setSearchTerm} setSortMode={setSortMode} />
        <EmergencyPlanTitles searchTerm={searchTerm} />
      </div>
    </div>
  );
};

export default EmergencyPage;
