import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { authService } from "../api/authService";

const Login: React.FC = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const navigate = useNavigate();

  const handleLogin = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    try {
      authService.login(username, password, () => {
        navigate("/contact");
      });
    } catch (error) {
      console.error("Login failed:", error);
      setError("Inloggningen misslyckades.");
    }
  };

  return (
    <div className="xl:pt-36 xl:flex xl:items-center xl:justify-center">
      <div className="pt-28 xl:pt-2 pb-4 xl:w-[30%] xl:bg-box-color xl:rounded-lg xl:max-h-[70vh] 2k:max-h-[80vh] xl:overflow-y-auto no-scrollbar">
        <div className="font-albert-sans text-normal-white px-6 text-sm pt-6 pb-2">
          <h1>Logga in</h1>
          {error && <p className="text-login">{error}</p>}
        </div>
        <div className="border-t-custom w-[91%] l:w-[93%] xl:w-[94%] mx-auto"></div>
        <form onSubmit={handleLogin} className="px-6 pt-4">
          <div className="mb-4">
            <label
              className="font-albert-sans text-base py-1 block text-normal-white"
              htmlFor="username"
            >
              Användarnamn:
            </label>
            <input
              type="text"
              id="username"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              className="w-full px-3 font-albert-sans py-2 mt-1 rounded-md xl:bg-darkblue bg-box-color text-white"
            />
          </div>
          <div className="mb-4">
            <label
              className="font-albert-sans text-base py-1 block text-normal-white"
              htmlFor="password"
            >
              Lösenord:
            </label>
            <input
              type="password"
              id="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              className="w-full px-3 py-2 mt-1 rounded-md xl:bg-darkblue bg-box-color text-white"
            />
          </div>
          <button
            type="submit"
            className="mt-4 font-albert-sans second-button-color text-normal-white px-4 py-2 rounded-md"
          >
            Logga in
          </button>
        </form>
      </div>
    </div>
  );
};

export default Login;
