import axios from "axios";
import { BehaviorSubject } from "rxjs";

const accessToken = new BehaviorSubject("");
const ACCESS_TOKEN_KEY = "accessToken";

const LOGIN_URL = "https://ice.wiznet.se/wp-json/api/v1/token";
const USER_EMAIL = "userEmail";
const USER_PASS = "userPassword";

const login = (email: string, password: string, callback: Function) => {
  return axios
    .post(LOGIN_URL, {
      username: email,
      password: password,
    })
    .then((res) => {
      const token = res.data.jwt_token;
      accessToken.next(token);
      localStorage.setItem(ACCESS_TOKEN_KEY, token);
      callback();
    });
};

const clearCache = () => {
  accessToken.next("");
  localStorage.removeItem(ACCESS_TOKEN_KEY);
  localStorage.removeItem(USER_EMAIL);
  localStorage.removeItem(USER_PASS);
};

const setAccessTokenFromLocalStorage = () => {
  const tokenFromLocalStorage = localStorage.getItem(ACCESS_TOKEN_KEY);
  if (tokenFromLocalStorage) {
    setAccessToken(tokenFromLocalStorage);
  }
};

const getUserEmailFromLocalStorage = () => {
  return localStorage.getItem(USER_EMAIL);
};

const getUserPasswordFromLocalStorage = () => {
  return localStorage.getItem(USER_PASS);
};

const getAccessTokenFromLocalStorage = () => {
  return localStorage.getItem(ACCESS_TOKEN_KEY);
};

const setAccessToken = (token: any) => {
  accessToken.next(token);
};

export const authService = {
  login,
  clearCache,
  setAccessTokenFromLocalStorage,
  getAccessTokenFromLocalStorage,
  getUserEmailFromLocalStorage,
  getUserPasswordFromLocalStorage,
  currentToken: accessToken.asObservable(),
  get tokenValue() {
    return getAccessTokenFromLocalStorage();
  },
};
